<template>
  <section class="form-group" @click.self="checkShow=false">
    <form class="container" ref="form" @click.self="checkShow=false" @submit.prevent>
      <h4>
        <p>{{$t('SubmitNFTProject').replace('NFT','DeFi')}}</p>
        <span class="text-sm mt-8">{{$t('SubmitNFTProjectTip').replace('NFT BIG BANG','this DeFi zone')}}</span>
      </h4>
      <div class="relative">
        <div class="list-name" ref="name">{{$t('SearchBSCProject')}} <span>*</span></div>
        <input
          type="text"
          class="search"
          :placeholder="$t('SearchProject')"
          v-model="searchInfo"
          @input="debouncedSearchProject"
        />
        <div class="radio" v-if="projectList&&projectList.length&&projectListShow">
          <p v-for="item in projectList" :key="item.ID" @click="selectProject(item)">
            <input type="radio" :value="item.ID"><img class="mr-6" :src="project_oss_link +item.img" width="20"><span>{{item.title}}</span>
          </p>
        </div>
        <input type="hidden" name="bscProjectID" v-model="bscProjectID">
      </div>
      <div v-show="selectedProject">
        <div class="relative" v-if="tagList&&tagList.length">
          <div class="list-name" ref="tag"> {{$t('Tag')}} <span>*</span></div>
          <p v-if="tag&&tag.length" class="input" @click="checkShow=!checkShow"><span v-for="(t,i) in selectedTag" :key="i">{{t.title}}{{i==tag.length-1?'':','}}</span></p>
          <p v-else @click="checkShow=!checkShow" class="input disabled">{{$t('SelectTags')}}</p>
          <div class="checkbox" v-show="checkShow">
            <p v-for="item in tagList" :key="item.ID">
              <input type="checkbox" v-model="selectedTag" :value="item"><span>{{item.title}}</span>
            </p>
          </div>
          <input type="hidden" name="tag" v-model="tagStr">
        </div>
        <!-- logo -->
        <div class="list-name" ref='img'>{{$t('Image')}} ({{$t('ImageSize')}} : 800*450 px) <span>*</span></div>
        <div class="upload-pic" id="fileContainer" :style="'backgroundImage:url('+ (img?'https://oss-us-cdn.maiziqianbao.net/'+img:require('@/assets/img/icon/photo.png'))+')'">
          <div id="file"></div>
          <input type="hidden" name="img" v-model="img">
        </div>
        <!-- 提交申请 -->
        <a href="javascript:;" class="submit-btn" @click="submitProject">{{$t('Submit')}}</a>
      </div>
      <router-link class="no-pro" v-if="noProject" to="/submit" v-html="$t('SearchNoProjectTip')"></router-link>
    </form>
  </section>
</template>
<script>
import OssUploader from '@/assets/js/oss.js'
export default {
  data(){
    return {
      img:"",
      selectedTag:[],
      tagList:null,
      checkShow: false,
      searchInfo:'',
      projectListShow:false,
      noProject:false,
      projectList:null,
      selectedProject:null,
    }
  },
  computed:{
    tag(){
      if(this.selectedTag&&this.selectedTag.length){
        let tags = this.selectedTag.map(v=>v.ID);
        return tags
      }
      return [];
    },
    tagStr(){
      if(this.tag&&this.tag.length){
        return JSON.stringify(this.tag)
      }
      return '';
    },
    bscProjectID(){
      if(this.selectedProject){
        return this.selectedProject.ID
      }
      return '';
    }
  },
  created(){
    this.getList();
    this.debouncedSearchProject = this.lodash.debounce(this.searchProject, 500);
  },
  mounted(){
    // 上传图片
    if(this.$refs.form){
      OssUploader.initUploader('bscDefi').then(img=>{
        this.img = img;
      })
    }
  },
  methods:{
    getList(){
      this.axios.get(this.domain+'getDefiSubmitTags').then(res=>{
        this.tagList = res?.data?.data
      })
    },
    searchProject() {
      this.noProject = false;
      //搜索项目
      this.searchInfo = this.searchInfo.trim();
      if(!this.searchInfo){
        //清空搜索框
        this.projectList = null;
        this.selectedProject = null
        return false;
      }

      if (this.selectedProject&&this.searchInfo == this.selectedProject.title) {
        return false;
      }
      this.$loading(1);
      var URL =
        this.domain +
        "searchBsc?key=" +
        this.searchInfo

      this.axios.get(URL).then((res) => {
        this.$loading(0);
        if (res.data.success) {
          this.projectList = res.data.data;
          if(this.projectList&&this.projectList.length){
            this.projectListShow = true;
          }else{
            this.selectedProject = null;
            this.noProject = true;
          }
        } else {
          this.noProject = true;
          console.log(res.data.message);
        }
      }).catch(()=>{
        this.noProject = true;
      })
    },
    selectProject(item){
      this.selectedProject = item;
      this.projectListShow = false;
      this.searchInfo = item.title;
    },
    validate() {
      if (!this.bscProjectID) {
        this.$toast(this.$t('EnterProject'));
        window.scrollTo(0, this.$refs.name.offsetTop - 74);
        return false;
      }
      if (!this.tag||this.tag.length==0) {
        this.$toast(this.$t('EnterTags'));
        window.scrollTo(0, this.$refs.tag.offsetTop - 74);
        return false;
      }
      // if (!this.img) {
      //   this.$toast(this.$t('EnterImage'));
      //   window.scrollTo(0, this.$refs.img.offsetTop - 74);
      //   return false;
      // }

      return true;
    },
    // 提交表单
    submitProject() {
      var formData = new FormData(this.$refs.form);

      if (!this.validate()) {
        return false;
      }

      // 去除空文件元素
      try {
        for (var pair of formData.entries()) {
          if (pair[1] instanceof File && pair[1].name == '' && pair[1].size == 0)
            formData.delete(pair[0]);
        }
      } catch (e) {}

      this.$loading(1);

      this.axios.post(this.domain + "submitDefiProject",formData).then(res=>{
        this.$loading(0);
        if (res.data.success) {
          this.$toast(this.$t('SubmitSuccess')).then(()=>{
            this.$router.push('/defi');
          })
        } else {
          this.$toast(res.data.message,3000);
        }
      }).catch(err=>{
        this.$loading(0);
        console.log(err);
        this.$toast(this.$t('Error'));
      })
    },
  }
}
</script>
<style scoped>
.form-group form{
  padding-bottom: 32px;
}
input.search{
  width: 100%;
  padding: 12px 24px 12px 32px;
  font-size: 16px;
  line-height: 1.5;
  border-radius: 0;
}
.no-pro{
  display: block;
  padding-top:32px;
  color: #000;
}
.text-sm{
  font-weight: normal;
  font-family: Rubik-Regular;
  color: var(--downColor);
}
</style>